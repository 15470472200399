<template>
	<div class="chart">
		<div ref="chart" style="width: 1550px; height: 620px;"></div>
	</div>
</template>

<script>
	export default {
		name: 'chart',
		props: {
			option: {
				type: Object,
				default: () => {}
			},
			labels: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				chart: null
			}
		},
		mounted() {
			
			this.chart = this.$echarts.init(this.$refs.chart)
			this.chart.setOption(this.option)
		},
		watch: {
			option:{
				handler(option){
				this.chart.setOption(this.option)
			},
            deep:true
			}
		},
    beforeDestroy() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
    },
	}
</script>

<style scpoed>
	
</style>
