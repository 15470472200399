import * as echarts from "echarts"
const options = {
    data1: data => {
      // console.log(data)
        return {
			xAxis: {
				type: "category",
				data: data.xData,
                axisLine: {//底部横线
                    show: true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                },
                //网格线
                splitLine:{
                    show:true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                 },
                //  刻度线
                 axisTick: {
                     show: false
                },
                axisLabel: {//底部文字设置
                    color: "#fff",
                    fontSize:16
                }                       
			},
			yAxis: {
				type: "value",
				boundaryGap: [0, 0.01],	
                axisLine: {//底部横线
                    show: true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                },
                //网格线
                splitLine:{
                    show:true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                 },
                //  刻度线
                 axisTick: {
                     show: false
                },
                axisLabel: {//底部文字设置
                    color: "#fff",
                    fontSize:16
                } 
			},
			series: [
				{ 
					name: '申请量', type: "bar", label: { show: true, position: 'top', textStyle: { //数值样式
                        color: '#F5E055',
                        fontSize: 14
                    }
                }, barWidth: 30,
					data: data.yData[0],
					itemStyle: {
						borderRadius: [4,4,0,0],
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: "#876800" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#FFFB8F" // 100% 处的颜色
                            }])
                        }
					} 
				},
				{ 
					name: '注册量', type: "bar", label: { show: true, position: 'top',textStyle: { //数值样式
                        color: '#1890FF',
                        fontSize: 14
                    } }, barWidth: 30, 
					data: data.yData[1],
					itemStyle: {
						borderRadius: [4,4,0,0],
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: "#002C8C " // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#91CAFF" // 100% 处的颜色
                            }])
                        }
					}
				},
			],
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
                formatter: function(params) {
                    var result = ''
                    var dotaDta = '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:#F5E055"></span>'
                    var dotaDta2 = '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:#1890FF"></span>'
                    result += params[0].axisValue + "</br>" + dotaDta +'申请量：' + params[0].data+ "</br>" + dotaDta2 +'注册量：' + params[1].data
                    return result
                }
			},
			legend: {
				data: ['申请量', '注册量'],
				top: '1%',
				right: '5%',
                textStyle: {
                    color: "#fff"
                }
			},
			grid: {
				top: '12%',
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
           
        }
    },
    data2: data => {
    var legendm = []
    // console.log(data)
		for(let year in data) {
			legendm.push(year);
		}
    // console.log(legendm)
        return {
            title: {
             
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                  label: {
                    backgroundColor: '#6a7985'
                  }
                }
              },
              legend: {
                data: legendm,
                top: '1%',
				        right: '5%',
                textStyle: {
                    color: "#fff"
                }
              },
              grid: {
                top: '12%',
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: [
                {
                  type: 'category',
                  data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                  axisLine: {//底部横线
                    show: true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                },
                //网格线
                splitLine:{
                    show:true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                 },
                //  刻度线
                 axisTick: {
                     show: false
                },
                axisLabel: {//底部文字设置
                    color: "#fff",
                    fontSize:16
                }   
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  axisLine: {//底部横线
                    show: true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                },
                //网格线
                splitLine:{
                    show:true,
                    lineStyle: {
                        color: "#CCCCCC",
                        opacity: 0.3
                    },
                 },
                //  刻度线
                 axisTick: {
                     show: false
                },
                axisLabel: {//底部文字设置
                    color: "#fff",
                    fontSize:16
                } 
                },
                
              ],
              series: [
                {
                  color: '#91CAFF',
                  name: legendm[0],
                  type: 'bar',
                 barWidth: 20,
                  areaStyle: {},
                  label: { show: true, position: 'top', textStyle: { //数值样式
                        color: '#91CAFF',
                        fontSize: 14
                    }
                },
                  borderRadius: [4,4,0,0],
                  data: data[legendm[0]]
                },
                {
                  color: '#FFFB8F',
                  name: legendm[1],
                  type: 'bar',
                  barWidth: 20,
                  label: { show: true, position: 'top', textStyle: { //数值样式
                        color: '#FFFB8F',
                        fontSize: 14
                    }
                },
                  areaStyle: {},
                 borderRadius: [4,4,0,0],
                  data: data[legendm[1]]
                },
                {
                  color: '#FFB492',
                  name: legendm[2],
                  type: 'bar',
                  label: { show: true, position: 'top', textStyle: { //数值样式
                        color: '#FFB492',
                        fontSize: 14
                    }
                },
                 borderRadius: [4,4,0,0],
                  areaStyle: {},
                  barWidth: 20,
                  data: data[legendm[2]]
                }
              ]
        }
    },
    // data2: data => {
    // var legendm = []
    // console.log(data)
		// for(let year in data) {
		// 	legendm.push(year);
		// }
    // // console.log(legendm)
    //     return {
    //         title: {
             
    //           },
    //           tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //               type: 'shadow',
    //               label: {
    //                 backgroundColor: '#6a7985'
    //               }
    //             }
    //           },
    //           legend: {
    //             data: legendm,
    //             top: '1%',
		// 		        right: '5%',
    //             textStyle: {
    //                 color: "#fff"
    //             }
    //           },
    //           grid: {
    //             top: '12%',
    //             left: '3%',
    //             right: '4%',
    //             bottom: '3%',
    //             containLabel: true
    //           },
    //           xAxis: [
    //             {
    //               type: 'category',
    //               boundaryGap: false,
    //               data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    //               axisLine: {//底部横线
    //                 show: true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //             },
    //             //网格线
    //             splitLine:{
    //                 show:true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //              },
    //             //  刻度线
    //              axisTick: {
    //                  show: false
    //             },
    //             axisLabel: {//底部文字设置
    //                 color: "#fff",
    //                 fontSize:16
    //             }   
    //             }
    //           ],
    //           yAxis: [
    //             {
    //               type: 'value',
    //               axisLine: {//底部横线
    //                 show: true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //             },
    //             //网格线
    //             splitLine:{
    //                 show:true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //              },
    //             //  刻度线
    //              axisTick: {
    //                  show: false
    //             },
    //             axisLabel: {//底部文字设置
    //                 color: "#fff",
    //                 fontSize:16
    //             } 
    //             },
                
    //           ],
    //           series: [
    //             {
    //               color: '#91CAFF',
    //               name: legendm[0],
    //               type: 'line',
    //               stack: 'Total',
    //               smooth: true,
		// 		          symbol: "none",
    //               areaStyle: {},
    //               emphasis: {
    //                 focus: 'series'
    //               },
    //               data: data[legendm[0]]
    //             },
    //             {
    //               color: '#FFFB8F',
    //               name: legendm[1],
    //               type: 'line',
    //               stack: 'Total',
    //               smooth: true,
		// 		          symbol: "none",
    //               areaStyle: {},
    //               emphasis: {
    //                 focus: 'series'
    //               },
    //               data: data[legendm[1]]
    //             },
    //             {
    //               color: '#FFB492',
    //               name: legendm[2],
    //               type: 'line',
    //               stack: 'Total',
    //               smooth: true,
		// 		           symbol: "none",
    //               areaStyle: {},
    //               emphasis: {
    //                 focus: 'series'
    //               },
    //               data: data[legendm[2]]
    //             }
    //           ]
    //     }
    // },
    data3: data => {
      var legendm = []
      // console.log(data)
      for(let year in data) {
        legendm.push(year);
      }
      // console.log(legendm)
          return {
              title: {
               
                },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow',
                    label: {
                      backgroundColor: '#6a7985'
                    }
                  }
                },
                legend: {
                  data: legendm,
                  top: '1%',
                  right: '5%',
                  textStyle: {
                      color: "#fff"
                  }
                },
                grid: {
                  top: '12%',
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    axisLine: {//底部横线
                      show: true,
                      lineStyle: {
                          color: "#CCCCCC",
                          opacity: 0.3
                      },
                  },
                  //网格线
                  splitLine:{
                      show:true,
                      lineStyle: {
                          color: "#CCCCCC",
                          opacity: 0.3
                      },
                   },
                  //  刻度线
                   axisTick: {
                       show: false
                  },
                  axisLabel: {//底部文字设置
                      color: "#fff",
                      fontSize:16
                  }   
                  }
                ],
                yAxis: [
                  {
                    type: 'value',
                    axisLine: {//底部横线
                      show: true,
                      lineStyle: {
                          color: "#CCCCCC",
                          opacity: 0.3
                      },
                  },
                  //网格线
                  splitLine:{
                      show:true,
                      lineStyle: {
                          color: "#CCCCCC",
                          opacity: 0.3
                      },
                   },
                  //  刻度线
                   axisTick: {
                       show: false
                  },
                  axisLabel: {//底部文字设置
                      color: "#fff",
                      fontSize:16
                  } 
                  },
                  
                ],
                series: [
                  {
                    color: '#91CAFF',
                    name: legendm[0],
                    type: 'bar',
                   barWidth: 20,
                    areaStyle: {},
                    label: { show: true, position: 'top', textStyle: { //数值样式
                          color: '#91CAFF',
                          fontSize: 14
                      }
                  },
                    borderRadius: [4,4,0,0],
                    data: data[legendm[0]]
                  },
                  {
                    color: '#FFFB8F',
                    name: legendm[1],
                    type: 'bar',
                    barWidth: 20,
                    label: { show: true, position: 'top', textStyle: { //数值样式
                          color: '#FFFB8F',
                          fontSize: 14
                      }
                  },
                    areaStyle: {},
                   borderRadius: [4,4,0,0],
                    data: data[legendm[1]]
                  },
                  {
                    color: '#FFB492',
                    name: legendm[2],
                    type: 'bar',
                    label: { show: true, position: 'top', textStyle: { //数值样式
                          color: '#FFB492',
                          fontSize: 14
                      }
                  },
                   borderRadius: [4,4,0,0],
                    areaStyle: {},
                    barWidth: 20,
                    data: data[legendm[2]]
                  }
                ]
          }
      },
    // data3: data => {
    //     var legendm = []
    //     // console.log(data)
    //         for(let year in data) {
    //             legendm.push(year);
    //         }
    //     // console.log(legendm)
    //         return {
    //             title: {
                 
    //               },
    //               tooltip: {
    //                 trigger: 'axis',
    //                 axisPointer: {
    //                   type: 'shadow',
    //                   label: {
    //                     backgroundColor: '#6a7985'
    //                   }
    //                 }
    //               },
    //               legend: {
    //                 data: legendm,
    //                 top: '1%',
    //                         right: '5%',
    //                 textStyle: {
    //                     color: "#fff"
    //                 }
    //               },
    //               grid: {
    //                 top: '12%',
    //                 left: '3%',
    //                 right: '4%',
    //                 bottom: '3%',
    //                 containLabel: true
    //               },
    //               xAxis: [
    //                 {
    //                   type: 'category',
    //                   boundaryGap: false,
    //                   data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    //                   axisLine: {//底部横线
    //                     show: true,
    //                     lineStyle: {
    //                         color: "#CCCCCC",
    //                         opacity: 0.3
    //                     },
    //                 },
    //                 //网格线
    //                 splitLine:{
    //                     show:true,
    //                     lineStyle: {
    //                         color: "#CCCCCC",
    //                         opacity: 0.3
    //                     },
    //                  },
    //                 //  刻度线
    //                  axisTick: {
    //                      show: false
    //                 },
    //                 axisLabel: {//底部文字设置
    //                     color: "#fff",
    //                     fontSize:16
    //                 }   
    //                 }
    //               ],
    //               yAxis: [
    //                 {
    //                   type: 'value',
    //                   axisLine: {//底部横线
    //                     show: true,
    //                     lineStyle: {
    //                         color: "#CCCCCC",
    //                         opacity: 0.3
    //                     },
    //                 },
    //                 //网格线
    //                 splitLine:{
    //                     show:true,
    //                     lineStyle: {
    //                         color: "#CCCCCC",
    //                         opacity: 0.3
    //                     },
    //                  },
    //                 //  刻度线
    //                  axisTick: {
    //                      show: false
    //                 },
    //                 axisLabel: {//底部文字设置
    //                     color: "#fff",
    //                     fontSize:16
    //                 } 
    //                 },
                    
    //               ],
    //               series: [
    //                 {
    //                   color: '#91CAFF',
    //                   name: legendm[0],
    //                   type: 'line',
    //                   stack: 'Total',
    //                   smooth: true,
    //                   symbol: "none",
    //                   areaStyle: {},
    //                   emphasis: {
    //                     focus: 'series'
    //                   },
    //                   data: data[legendm[0]]
    //                 },
    //                 {
    //                   color: '#FFFB8F',
    //                   name: legendm[1],
    //                   type: 'line',
    //                   stack: 'Total',
    //                   smooth: true,
    //                   symbol: "none",
    //                   areaStyle: {},
    //                   emphasis: {
    //                     focus: 'series'
    //                   },
    //                   data: data[legendm[1]]
    //                 },
    //                 {
    //                   color: '#FFB492',
    //                   name: legendm[2],
    //                   type: 'line',
    //                   stack: 'Total',
    //                   smooth: true,
    //                   symbol: "none",
    //                   areaStyle: {},
    //                   emphasis: {
    //                     focus: 'series'
    //                   },
    //                   data: data[legendm[2]]
    //                 }
    //               ]
    //         }
    //     },
        data4: data => {
              return {
                  title: {
                   
                    },
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow',
                        label: {
                          backgroundColor: '#6a7985'
                        }
                      }
                  },
                  legend: {
                    data:[],
                    top: '1%',
                    right: '5%',
                  },
                    grid: {
                      top: '12%',
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    xAxis: [
                      {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xData,
                        axisLine: {//底部横线
                          show: true,
                          lineStyle: {
                              color: "#CCCCCC",
                              opacity: 0.3
                          },
                      },
                      //网格线
                      splitLine:{
                          show:true,
                          lineStyle: {
                              color: "#CCCCCC",
                              opacity: 0.3
                          },
                       },
                      //  刻度线
                       axisTick: {
                           show: false
                      },
                      axisLabel: {//底部文字设置
                          color: "#fff",
                          fontSize:16
                      }   
                      }
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        axisLine: {//底部横线
                          show: true,
                          lineStyle: {
                              color: "#CCCCCC",
                              opacity: 0.3
                          },
                      },
                      //网格线
                      splitLine:{
                          show:true,
                          lineStyle: {
                              color: "#CCCCCC",
                              opacity: 0.3
                          },
                       },
                      //  刻度线
                       axisTick: {
                           show: false
                      },
                      axisLabel: {//底部文字设置
                          color: "#fff",
                          fontSize:16
                      } 
                      }
                    ],
                    series: [
                      {
                        color: '#91CAFF',
                        name: '',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                          focus: 'series'
                        },
                        data: data.yData
                      }
                    ]
              }
          },
          data5: data => {
            return {
                title: {
                 
                  },
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow',
                      label: {
                        backgroundColor: '#6a7985'
                      }
                    }
                },
                legend: {
                  data:[],
                  top: '1%',
                  right: '5%',
                },
                  grid: {
                    top: '12%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  xAxis: [
                    {
                      type: 'category',
                      boundaryGap: false,
                      data: data.xData,
                      axisLine: {//底部横线
                        show: true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                    },
                    //网格线
                    splitLine:{
                        show:true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                     },
                    //  刻度线
                     axisTick: {
                         show: false
                    },
                    axisLabel: {//底部文字设置
                        color: "#fff",
                        fontSize:16
                    }   
                    }
                  ],
                  yAxis: [
                    {
                      type: 'value',
                      axisLine: {//底部横线
                        show: true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                    },
                    //网格线
                    splitLine:{
                        show:true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                     },
                    //  刻度线
                     axisTick: {
                         show: false
                    },
                    axisLabel: {//底部文字设置
                        color: "#fff",
                        fontSize:16
                    } 
                    }
                  ],
                  series: [
                    {
                      color: '#91CAFF',
                      name: '',
                      type: 'line',
                      stack: 'Total',
                      smooth: true,
                      symbol: "none",
                      areaStyle: {},
                      emphasis: {
                        focus: 'series'
                      },
                      data: data.yData
                    }
                  ]
            }
        },
        data6: data => {
          // console.log(data)
            return {
          xAxis: {
            type: "category",
            data: data.xData,
                    axisLine: {//底部横线
                        show: true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                    },
                    //网格线
                    splitLine:{
                        show:true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                     },
                    //  刻度线
                     axisTick: {
                         show: false
                    },
                    axisLabel: {//底部文字设置
                        color: "#fff",
                        fontSize:16
                    }                       
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, 0.01],	
                    axisLine: {//底部横线
                        show: true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                    },
                    //网格线
                    splitLine:{
                        show:true,
                        lineStyle: {
                            color: "#CCCCCC",
                            opacity: 0.3
                        },
                     },
                    //  刻度线
                     axisTick: {
                         show: false
                    },
                    axisLabel: {//底部文字设置
                        color: "#fff",
                        fontSize:16
                    } 
          },
          series: [
            { 
              name: '申请量', type: "bar", label: { show: true, position: 'top', textStyle: { //数值样式
                            color: '#F5E055',
                            fontSize: 14
                        }
                    }, barWidth: 30,
              data: data.yData[0],
              itemStyle: {
                borderRadius: [4,4,0,0],
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: "#876800" // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: "#FFFB8F" // 100% 处的颜色
                                }])
                            }
              } 
            },
            { 
              name: '授权量', type: "bar", label: { show: true, position: 'top',textStyle: { //数值样式
                            color: '#1890FF',
                            fontSize: 14
                        } }, barWidth: 30, 
              data: data.yData[1],
              itemStyle: {
                borderRadius: [4,4,0,0],
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: "#002C8C " // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: "#91CAFF" // 100% 处的颜色
                                }])
                            }
              }
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
                    formatter: function(params) {
                        var result = ''
                        var dotaDta = '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:#F5E055"></span>'
                        var dotaDta2 = '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:#1890FF"></span>'
                        result += params[0].axisValue + "</br>" + dotaDta +'申请量：' + params[0].data+ "</br>" + dotaDta2 +'授权量：' + params[1].data
                        return result
                    }
          },
          legend: {
            data: ['申请量', '授权量'],
            top: '1%',
            right: '5%',
                    textStyle: {
                        color: "#fff"
                    }
          },
          grid: {
            top: '12%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
               
            }
        },
    //     data6: data => {
    //       return {
    //           title: {
               
    //             },
    //             tooltip: {
    //               trigger: 'axis',
    //               axisPointer: {
    //                 type: 'shadow',
    //                 label: {
    //                   backgroundColor: '#6a7985'
    //                 }
    //               }
    //           },
    //           legend: {
    //             data:[],
    //             top: '1%',
    //             right: '5%',
    //           },
    //             grid: {
    //               top: '12%',
    //               left: '3%',
    //               right: '4%',
    //               bottom: '3%',
    //               containLabel: true
    //             },
    //             xAxis: [
    //               {
    //                 type: 'category',
    //                 boundaryGap: false,
    //                 data: data.xData,
    //                 axisLine: {//底部横线
    //                   show: true,
    //                   lineStyle: {
    //                       color: "#CCCCCC",
    //                       opacity: 0.3
    //                   },
    //               },
    //               //网格线
    //               splitLine:{
    //                   show:true,
    //                   lineStyle: {
    //                       color: "#CCCCCC",
    //                       opacity: 0.3
    //                   },
    //                },
    //               //  刻度线
    //                axisTick: {
    //                    show: false
    //               },
    //               axisLabel: {//底部文字设置
    //                   color: "#fff",
    //                   fontSize:16
    //               }   
    //               }
    //             ],
    //             yAxis: [
    //               {
    //                 type: 'value',
    //                 axisLine: {//底部横线
    //                   show: true,
    //                   lineStyle: {
    //                       color: "#CCCCCC",
    //                       opacity: 0.3
    //                   },
    //               },
    //               //网格线
    //               splitLine:{
    //                   show:true,
    //                   lineStyle: {
    //                       color: "#CCCCCC",
    //                       opacity: 0.3
    //                   },
    //                },
    //               //  刻度线
    //                axisTick: {
    //                    show: false
    //               },
    //               axisLabel: {//底部文字设置
    //                   color: "#fff",
    //                   fontSize:16
    //               } 
    //               }
    //             ],
    //             series: [
    //               {
    //                 color: '#91CAFF',
    //                 name: '',
    //                 type: 'line',
    //                 stack: 'Total',
    //                 smooth: true,
    //                 symbol: "none",
    //                 areaStyle: {},
    //                 // areaStyle: {
    //                 //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                 //     {
    //                 //       offset: 0,
    //                 //       color: '#002C8C'
    //                 //     },
    //                 //     {
    //                 //       offset: 1,
    //                 //       color: '#91CAFF'
    //                 //     }
    //                 //   ])
    //                 // },
    //                 // itemStyle: {
    //                 //   color: 'rgba(255,174,19,.1)'
    //                 // },
    //                 // lineStyle: {
    //                 //   color: 'rgba(255,174,19,.1)'
    //                 // },
    //                 emphasis: {
    //                   focus: 'series'
    //                 },
    //                 data: data.yData
    //               }
    //             ]
    //       }
    //   },
    //   data7: data => {
    //     return {
    //         title: {
             
    //           },
    //           tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //               type: 'shadow',
    //               label: {
    //                 backgroundColor: '#6a7985'
    //               }
    //             }
    //         },
    //         legend: {
    //           data:[],
    //           top: '1%',
    //           right: '5%',
    //         },
    //           grid: {
    //             top: '12%',
    //             left: '3%',
    //             right: '4%',
    //             bottom: '3%',
    //             containLabel: true
    //           },
    //           xAxis: [
    //             {
    //               type: 'category',
    //               boundaryGap: false,
    //               data: data.xData,
    //               axisLine: {//底部横线
    //                 show: true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //             },
    //             //网格线
    //             splitLine:{
    //                 show:true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //              },
    //             //  刻度线
    //              axisTick: {
    //                  show: false
    //             },
    //             axisLabel: {//底部文字设置
    //                 color: "#fff",
    //                 fontSize:16
    //             }   
    //             }
    //           ],
    //           yAxis: [
    //             {
    //               type: 'value',
    //               axisLine: {//底部横线
    //                 show: true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //             },
    //             //网格线
    //             splitLine:{
    //                 show:true,
    //                 lineStyle: {
    //                     color: "#CCCCCC",
    //                     opacity: 0.3
    //                 },
    //              },
    //             //  刻度线
    //              axisTick: {
    //                  show: false
    //             },
    //             axisLabel: {//底部文字设置
    //                 color: "#fff",
    //                 fontSize:16
    //             } 
    //             }
    //           ],
    //           series: [
    //             {
    //               color: '#91CAFF',
    //               name: '',
    //               type: 'line',
    //               stack: 'Total',
    //               smooth: true,
    //               symbol: "none",
    //               areaStyle: {},
    //               emphasis: {
    //                 focus: 'series'
    //               },
    //               data: data.yData
    //             }
    //           ]
    //     }
    // },
}

export function getChartOption(name, data) {
    if (options[name]) return options[name](data)
    return false
}
